import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import Footer from '../components/Footer';
import BannerSection from '../components/BannerSection';

const PricingPage = () => {
  const scrollToCard = (id: number) => {
    const cardElement = document.getElementById(`pricing-card-${id}`);
    const yOffset = -120;
  
    if (cardElement) {
      const elementPosition = cardElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
      window.scrollTo({
        top: elementPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  const pricingCards = [
    {
      id: 1,
      subscription: true,
      title: "Štandardné predplatné",
      price: "89 €",
      period: "Mesačne",
      yearlyPrice: "899 €",
      yearlyDiscount: "1 068 €",
      yearlySavings: "169 €",
      features: [
        "Počiatočná tvorba 3D modelov jedál (do 40 modelov)",
        "Priebežná aktualizácia menu",
        "Prispôsobenie menu podľa vašich požiadaviek",
        "Profesionálne fotografie jedál",
        "Hosting, údržba a podpora",
      ],
    },
    {
      id: 2,
      subscription: true,
      title: "Prémiové predplatné",
      price: "129 €",
      period: "Mesačne",
      yearlyPrice: "1 299 €",
      yearlyDiscount: "1548 €",
      yearlySavings: "249 €",
      features: [
        "Všetky výhody štandardného predplatného",
        "Počiatočná tvorba až 60 3D modelov jedál",
        "Zloženie jedla zobrazené priamo v modeloch",
        "Nutričné hodnoty jedál"
      ],
    },
    {
      id: 3,
      subscription: false,
      title: "Jednorázový balík",
      price: "5 999 €",
      period: "jednorázovo + ročný servisný poplatok",
      yearlyPrice: undefined,
      yearlyDiscount: undefined,
      yearlySavings: undefined,
      features: [
        "Počiatočná tvorba 3D modelov jedál (do 60 modelov)",
        "Prispôsobenie menu podľa vašich požiadaviek",
        "Profesionálne fotografie jedál",
        "Hosting a podpora",
      ],
    },
  ];

  return (
    <div className="bg-[#EBFFE8] min-h-screen">
      <div className="flex justify-between items-center p-6">
        <img src="/images/logo-pricing.webp" alt="Logo" className="h-12" />
      </div>

      {/*<div className="block lg:hidden bg-white shadow p-4 sticky top-0 z-10">
        <div className="grid grid-cols-3 gap-4 text-center">
          {pricingCards.map((card) => (
            <button
              key={card.id}
              onClick={() => scrollToCard(card.id)}
              className="text-sm font-bold"
            >
              <div className="text-black">{card.title}</div>
              <div className='text-green-600'>{card.price}{card.subscription ? ' mesačne' : ' jednorázovo'}</div>
            </button>
          ))}
        </div>
      </div>*/}

      <div className="flex flex-col justify-end p-8 pt-4 relative mt-2">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {pricingCards.map((card) => (
            <div
              id={`pricing-card-${card.id}`}
              key={card.id}
              className="bg-white shadow-md rounded-lg p-6 max-w-lg w-full mx-auto flex flex-col justify-between h-full"
            >
              <div>
                <h2 className="text-3xl font-bold text-center mb-4">{card.title}</h2>
                <p className="text-5xl font-bold text-center text-green-600 mb-2">{card.price}</p>
                <p className="text-sm text-center text-gray-600 mb-4">{card.period}</p>
                <div className="border-t border-gray-200 py-4">
                  <p className="text-sm text-center">{card.subscription ? "Predplatné zahŕňa:" : "Balíček zahŕňa:"}</p>
                  <ul className="list-none text-left mt-2">
                    {card.features.map((feature, index) => (
                      <li key={index} className="flex items-center mb-3">
                        <FaCheckCircle className="text-green-600 mr-2 h-4 w-4" />
                        <span className="flex-1">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="mt-auto text-center">
                {card.subscription && <>
                  <p className="text-sm text-gray-600">Ročne:</p>
                  <div className="flex justify-center items-end">
                    <span className="line-through text-red-500 text-lg mr-2">{card.yearlyDiscount}</span>
                    <p className="text-2xl font-bold text-green-600">{card.yearlyPrice}</p>
                  </div>
                  <p className="text-sm text-gray-600 mb-4">(ušetríte {card.yearlySavings})</p>
                </>}
                <button
                  className="bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 transition duration-300"
                  onClick={() => scrollToBottom()}
                >
                  Mám záujem
                </button>
                {card.subscription && <p className="mt-4 text-xs text-gray-500">
                  Po uplynutí ročnej viazanosti môžete službu kedykoľvek zrušiť. V prípade špecifických požiadaviek nás
                  kontaktujte.
                </p>}
                {!card.subscription && <p className="mt-4 text-xs text-gray-500">
                  K službe je potrebné uhradiť ročný servisný poplatok 49 EUR. Ak máte akékoľvek otázky, neváhajte nás kontaktovať.
                </p>}
              </div>
            </div>
          ))}
        </div>
        <BannerSection />
      </div>

      <Footer />
    </div>
  );
};

export default PricingPage;
